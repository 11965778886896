import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import wtsFunctions from '../../services/ServiceManager.js';
import rotateIcon from '../../assets/images/icons/rotate.svg';
class Demo extends Component {
  toFlip = false;  

  constructor(props) {  
    super(props);
    const demoId = this.props.demoId ? this.props.demoId : window.location.href.split('/').slice(-1).pop();
    const orientation = window.innerWidth > window.innerHeight ? 'L' : 'P';
    var currentDemoOrientation = null;
    var currentDemoPath = null;
    var initFlip = false;
    this.state = {toFlip:false};
    if(!window.terminalId || !window.currentDemo.orientation) {
      window.terminalId = window.location.href.split('/')[5];
      window.eds = window.location.href.split('/')[4];
      wtsFunctions.getDemoById(demoId).then(() => {
        currentDemoOrientation = window.currentDemo.orientation ? (window.currentDemo.orientation.hasOwnProperty('R') ? 'R' : (window.currentDemo.orientation.hasOwnProperty('P') ? 'P' : 'L')) : null;
        currentDemoPath = window.currentDemo.orientation ? window.currentDemo.orientation[currentDemoOrientation] : null;
        initFlip = (window.currentDemo && currentDemoOrientation !== 'R' && currentDemoOrientation !== orientation) ? true : false;
        this.setState({currentDemo : window.currentDemo, currentDemoPath, currentOrientation: orientation, toFlip : initFlip});
      });  
    }
    else{
      currentDemoOrientation = window.currentDemo.orientation ? (window.currentDemo.orientation.hasOwnProperty('R') ? 'R' : (window.currentDemo.orientation.hasOwnProperty('P') ? 'P' : 'L')) : null;
      currentDemoPath = window.currentDemo.orientation ? window.currentDemo.orientation[currentDemoOrientation] : null;
      initFlip = (window.currentDemo && currentDemoOrientation !== 'R' && currentDemoOrientation !== orientation) ? true : false;
      this.state = {currentDemo : window.currentDemo, currentDemoPath, currentOrientation: orientation, toFlip : initFlip};
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    // inner frame actions
    const eventList = ['mousedown', 'touchstart'];
    var iframe = document.querySelector('iframe');
    iframe.addEventListener('load', function(e)
    {
      eventList.forEach(function(type)
      {
        try {
          iframe.contentWindow.document.querySelectorAll('iframe').forEach((elem, key) => elem.addEventListener(type, function(){
            window.idleRef.reset();
          }));
        } catch(err) {
          console.error(err)
        }
        
        /*
        try{
          iframe.contentWindow.document.addEventListener(type, function(e)
          {
            console.log('iframe event', type, e);
            window.idleRef.reset();
            // subiframe //
            var subiframe = iframe.contentWindow.document.querySelector('iframe');
            subiframe.addEventListener('load', function(e)
            {
              eventList.forEach(function(type)
              {
                try{
                  subiframe.contentWindow.document.addEventListener(type, function(e)
                  {
                    console.log('subiframe event', type, e);
                    window.idleRef.reset();
                  });
                } catch(err){
                  console.error(err)
                }
              });
            });
            // end subiframe //
          });
        } catch (err) {
          console.error(err)
        }
        */
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    if(this.state.currentDemo){
      const newOrientation = window.innerWidth > window.innerHeight ? 'L' : 'P';
      const currentDemoOrientation = this.state.currentDemo.orientation ? (this.state.currentDemo.orientation.hasOwnProperty('R') ? 'R' : (this.state.currentDemo.orientation.hasOwnProperty('P') ? 'P' : 'L')) : null;
      // console.log(currentDemoOrientation, newOrientation);
      if(currentDemoOrientation !== null && this.state.currentDemo && currentDemoOrientation !== 'R'){
        if(currentDemoOrientation === newOrientation){
          this.setState({toFlip : false});
        }
        else{
          this.setState({toFlip : true});
        }
      }
    }
  }

  render() {   
    return (
      <>
      {
      /* this.state.currentDemo === null &&  
        <Redirect to='/' />
      */
      }
      { this.state.toFlip &&  <div className="rotation-popup"><div className="rotation-message"><img src={rotateIcon} alt="Rotate" /><span>Merci de pivoter l'écran</span></div></div> }
      <Container fluid className="p-0 demo-frame-container">
          <iframe id="demo-frame" title = "Demo" width="100%" src={this.state.currentDemo ? this.state.currentDemoPath : ''} key={this.demoId}></iframe>
      </Container>
      </>
    );
  }
}

export default Demo;
