import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Header from '../layout/Header.js';
import Footer from '../layout/Footer.js';
import DemosList from '../components/demo/DemosList.js';
import appVars from '../services/appGlobals.ts';


class HomePage extends Component {
  
  constructor(props) {
    super(props);
    window.terminalId = this.props.match.params.terminalId;
    window.currentDemo = null;
    window.visitId = null;
    this.state = {screensaverOn: window.screensaverOn, screensaverDuration: window.screensaver_display_duration, screensaverType: window.file_screensaver_type, screensaverFilePortrait: window.file_screensaver_portrait, screensaverFileLandscape: window.file_screensaver_landscape};    
    this.updateGlobals = this.updateGlobals.bind(this)
    this.closeScreensaver = this.closeScreensaver.bind(this)
    // this.videoEnded = this.videoEnded.bind(this)
  }

  componentDidMount() {
    // window.videoIterations = 1;
    if(this.state.screensaverType === "Video" && document.querySelector('video')){
      document.querySelector('video').currentTime = 0;
      document.querySelector('video').play();
      document.querySelector('video').addEventListener("ended", this.videoEnded, false);
    } 
  }

  componentWillUnmount() {
    if(this.state.screensaverType === "Video" && document.querySelector('video'))
      document.querySelector('video').removeEventListener("ended");
  }

  componentWillReceiveProps(props) {
    console.log("receive props");
    if(this.state.screensaverOn != props.screensaverOn && props.screensaverOn) {
      this.setVideoTimer();
    }
    this.setState({ screensaverOn: props.screensaverOn }, () => {
      if(props.screensaverOn && this.state.screensaverType === "Video" && document.querySelector('video')){
        document.querySelector('video').currentTime = 0;
        document.querySelector('video').play();
      }    
      // console.log(this.state.screensaverOn, 'screensaverOn');
    }); 
  }

  setVideoTimer() {
    // console.log("Set video timer");
    if(this.state.screensaverDuration){
      setTimeout(() => {
        this.closeScreensaver();
      }, this.state.screensaverDuration * 1000);
    } else {
      // setTimeout(() => {
      //   this.closeScreensaver();
      // }, window.inactivityTimer * 1000);
    }
  }

  updateGlobals() {
    //screensaverOn: window.screensaverOn, 
    this.setState({screensaverDuration: window.screensaver_display_duration, screensaverType: window.file_screensaver_type, screensaverFile: window.file_screensaver});
  }

  closeScreensaver() {
    if(this.state.screensaverOn){
      window.screensaverOn=false;
      this.setState({screensaverOn: false})
      window.idleRef.reset();
    }
  }

  videoEnded() {
    /**
     * For video iteration count
     */
    if (window.videoIterations < window.screensaver_display_duration) {
      document.querySelector('video').currentTime = 0;
      document.querySelector('video').play();
      window.videoIterations++;
    }
    else {
      this.closeScreensaver();
    }
  }

  render() {
    const backgroundImage = (window.innerWidth > window.innerHeight) ? (appVars.backgrounds.landscape ? appVars.backgrounds.landscape : appVars.backgrounds.default_landscape) : (appVars.backgrounds.portrait ? appVars.backgrounds.portrait : appVars.backgrounds.default_portrait);
    const screensaverFile = (window.innerWidth > window.innerHeight) ? (appVars.video.landscape ?? appVars.video.portrait) : (appVars.video.portrait ?? appVars.video.landscape);
    return (
      <div className="App" id="home-container" style={{ backgroundImage:`url(${backgroundImage})` }}>
        {
          window.screensaver_display && this.state.screensaverOn &&
          <div id="screensaver" onClick={() => this.closeScreensaver()}>
          {
            this.state.screensaverType === 'Video' && 
            <video id="video" width="100%" height="100%" autoPlay loop src={screensaverFile}>
              <source src={screensaverFile}/>
            </video>
          }
          {
            this.state.screensaverType === 'Image' && 
            <img src={screensaverFile} width="100%" height="auto" />
          }
        </div>
        }
        <Header>
        </Header>
        <div className="agency-name" id="agency-name-container"><h2>Agence de</h2><h1 className="aname" id="agency-name">{window.agency_name}</h1></div>
        <DemosList updateGlobals={this.updateGlobals}>
        </DemosList>
        <Footer>
        </Footer>
      </div>
    );
  }
}

export default withRouter(HomePage);
