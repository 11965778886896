import React, { Component } from 'react';
// import axios from 'axios';
import config from '../../config.js';
import Keyboard from '../keyboard/Keyboard.js';
import Popup from '../popup/Popup.js';
import backIcon from '../../assets/images/icons/correct.svg';

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input:{email:'', phone:''},
      currentInput: window.currentDemo.share.mail === "0" ? 'phone' : 'email',
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateInput = this.updateInput.bind(this);
  }
  
  handleChange(event) {
    // const name = event.target.name;
    // this.setState({input: {[name]:event.target.value}});
    const input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({input});
  }
  
  handleFocus(event) {
    this.setState({currentInput: event.target.name});
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.validate()){
      const share = { input: this.state.input, visit_id: window.visitId };;
      fetch(config.apiGateway.URL+'/share/data',{
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(share)
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        this.setState({sucessMessage:'Informations envoyées !'});
        // this.setState({ shareId: data.id })
        const timer = setTimeout(() => {
          this.props.hideShare();
        }, 20000);
        return () => clearTimeout(timer);
      })
      .catch(error => {
        //error
        console.log(error);
      });
    }
  }
  validate(){
    let input = this.state.input;
    let pattern = '';
    let errors = {};
    let isValid = true;
    const emailRequired = window.currentDemo.share.mail === "1";
    const phoneRequired = window.currentDemo.share.tel === "1";
    if (input["email"] === "" && input["phone"] === "" && phoneRequired && emailRequired) {
      isValid = false;
      errors["email"] = "Veuillez saisir votre adresse email";
      errors["phone"] = "et/ou votre numéro de téléphone.";
    }
    else if(input["email"] === "" && !phoneRequired && emailRequired) {
      isValid = false;
      errors["email"] = "Veuillez saisir votre adresse email";
    }
    else if(input["phone"] === "" && phoneRequired && !emailRequired) {
      isValid = false;
      errors["phone"] = "Veuillez saisir votre numéro de téléphone.";
    }
    else{      
      if ((input["email"] !== "" && input["email"] !== undefined)) {
          // console.log(input["email"]);
        // pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        pattern = new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["email"] = "Veuillez saisir une adresse email valide.";
        }
      }
      if ((input["phone"] !== "" && input["phone"] !== undefined && input['phone'].length !== 10)) {
        /*
        ^
          (?:(?:\+|00)33|0)     # Dialing code
          \s*[1-9]              # First number (from 1 to 9)
          (?:[\s.-]*\d{2}){4}   # End of the phone number
        $
        */
        // pattern = new RegExp(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i);
        pattern = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
        if (!pattern.test(input["phone"])) {
          isValid = false;
          errors["phone"] = "Veuillez saisir un numéro de téléphone valide.";
        }
      }
    }
    this.setState({
      errors: errors
    });

    return isValid;
  }

  updateInput(key) {
    const currentInput = this.state.currentInput;
    let input = this.state.input;
    /*
    // Phone numbers must start with 06 or 07
    if(currentInput == 'phone' ){

    }
    */
    // this.state.input[currentInput] = (this.state.input[currentInput] !== undefined) ? this.state.input[currentInput] : '';
    var currentState = this.state;
    currentState.input[currentInput] = (this.state.input[currentInput] !== undefined) ? this.state.input[currentInput] : '';
    this.setState(currentState);
    if(key === 'back'){
      input[currentInput] = (this.state.input[currentInput] && this.state.input[currentInput].length) > 0 ? this.state.input[currentInput].slice(0, -1) : '';
      this.setState(input);
    }
    else{
      input[currentInput] = this.state.input[currentInput] + key;
      this.setState(input);
    }
  }


  render() {
    return (
      <>
        {!this.state.sucessMessage && 
        <form className = "share-container" onSubmit={this.handleSubmit}>
          {
            window.currentDemo.share.hasOwnProperty('mail') && window.currentDemo.share.mail === "1" &&
            <>
              <input type="text" name="email" id="email" autoComplete="off" value={this.state.input.email} onChange={this.handleChange} onFocus={this.handleFocus} className={ this.state.errors.email ? 'text-input error' : 'text-input'} placeholder="Taper votre adresse e-mail" />
              <div className="text-danger text-left ml-3 pl-3">{this.state.errors.email}</div>
            </>
          }
          {
            window.currentDemo.share.hasOwnProperty('tel') && window.currentDemo.share.tel === "1" &&
            <>
              <input type="text" name="phone" id="phone" autoComplete="off" value={this.state.input.phone} onChange={this.handleChange} onFocus={this.handleFocus} className={ this.state.errors.phone ? 'text-input error' : 'text-input'} placeholder="Taper votre numéro de téléphone" />
              <div className="text-danger text-left ml-3 pl-3">{this.state.errors.phone}</div>
            </> 
          }
          <Keyboard currentInput={this.state.currentInput} updateInput={this.updateInput}></Keyboard>
          <input type="submit" className="share-btn" value="Valider"/><div className="key-button back-key" key="back" onClick={() => this.updateInput('back')}> <img src={backIcon} alt="back" /> </div>
        </form>
        }
        {this.state.sucessMessage && <Popup success="true" message={this.state.sucessMessage}></Popup>}
      </>
    );
  }
}

export default Share;
