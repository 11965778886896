import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/fr';
import { Container, Row, Col } from 'react-bootstrap';

class Header extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      currentDate: new Date(),
    }
    Moment.globalLocale = 'fr';
    Moment.globalLocal = true;
    
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({currentDate: new Date()})
    }, 1000);
  }

  render() {
    return (
      <header className="App-header">
        <Container fluid>
          <Row>
            <Col lg={8} id="current-date" className="text-left text-capitalize"><Moment locale="fr" format="dddd DD MMMM YYYY">{this.state.currentDate}</Moment></Col>
            <Col lg={4} id="current-time" className="text-right"><Moment locale="fr" format="HH:mm">{this.state.currentDate}</Moment></Col>
          </Row>
        </Container>        
      </header>
    );
  }
}

export default Header;
