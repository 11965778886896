import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Footer from '../layout/Footer.js';
import wtsFunctions from '../services/ServiceManager.js';


function AffluencePage() {
  const aff = wtsFunctions.getAffluenceDemo();
  const currentDemoOrientation = aff.orientation ? (aff.orientation.hasOwnProperty('R') ? 'R' : (aff.orientation.hasOwnProperty('P') ? 'P' : 'L')) : null;
  const currentDemoPath = aff.orientation ? aff.orientation[currentDemoOrientation] : null;
  return (
    <div className="App">
      <Container fluid className="p-0 demo-frame-container">
          <iframe id="demo-frame" title = "Demo" width="100%" src={currentDemoPath} key={aff.demoId}></iframe>
      </Container>
      <Footer>
      </Footer>
    </div>
  );
}

export default AffluencePage;
