// import backgroundUrl from '../assets/images/bkg.jpg';
// import backgroundUrlLandscape from '../assets/images/bkg-l.jpg';

let appVars = {
    data: {agency_name: ''},
    demos: [],
    currentDemo : null,
    defaultLang : 'fr',
    backgrounds: {portrait:'', landscape:'', default_landscape:'', default_portrait:''},
    video: {portrait:'', landscape:''}
};

export default appVars;