import React, { Component } from 'react';
import './Keyboard.css';
class Keyboard extends Component {
  keyboard = [
    ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ],
    ["A", "Z", "E", "R", "T", "Y", "U", "I", "O", "P", ],
    ["Q", "S", "D", "F", "G", "H", "J", "K", "L", "M", ],
    ["W", "X", "C", "V", "B", "N", "_", "-", ".", "@", ],
  ];

  getKeyButton(key){           
        let keyClass = "key-button";
        if(key === " "){
            keyClass += " space-key";
        }
        else if(key === "back") {
            keyClass += " back-key";
        }    
        return <div className={keyClass} key={key} onClick={() => this.props.updateInput(key)}> {key} </div>;        
        
  }
  tablerows(keys) {
    return keys.map((rows, index) => {
        let row = rows.map(key => this.getKeyButton(key)); 
        return <div className="keyboard-row" key={index}>{row}</div>;
    });
  }

  render(){
    return (
      <div id="keys">{this.tablerows(this.keyboard)}</div>
    );
  }

}
export default Keyboard;