import config from '../config.js';
import appVars from './appGlobals.ts';

const wtsFunctions = {};

wtsFunctions.getDemoById = async (id) => {
    if(!appVars.demos.length){
      await wtsFunctions.getDemos(true)
      .then(() => {
        appVars.demos.map((demo) =>
        {
          if(parseInt(demo.app_id) === parseInt(id)){
            window.currentDemo = demo;
            return window.currentDemo;
          }
          return false;
        });
        return false;
      })
      .catch(error => {
        //error
        return false;
      });
    }
    else{
      appVars.demos.map((demo) =>
      {
          if(parseInt(demo.app_id) === parseInt(id)){
            window.currentDemo = demo;
          }
          return window.currentDemo;
      });
    }
};

wtsFunctions.getShareKeyboardKeys = () => {
  return [
    ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ],
    ["A", "Z", "E", "R", "T", "Y", "U", "I", "O", "P", ],
    ["Q", "S", "D", "F", "G", "H", "J", "K", "L", "M", ],
    ["W", "X", "C", "V", "B", "N", "-", ".", "@", "back", ],
  ];
}

wtsFunctions.getAffluenceDemo = () => {
  return {
    "app_id": 'AFFLUENE',
    "app_name": "AFFLUENCE",
    "app_name_color": "#FFFFFF",
    "position": "1",
    "app_icon": "affluence.png",
    "orientation": {
        "R": config.apiGateway.WTS + "/admin/affluence.php?IDA=" + window.eds
    },
    "share": {
        "mail": "0",
        "tel": "0"
    }
  };
}

wtsFunctions.getDemos = async (single = false) => {
  const response = {demos:[], error:false, message: ''};
  const terminalId = window.terminalId ? window.terminalId : (single ? window.location.href.split('/')[5] : window.location.href.split('/').slice(-1).pop());
  const eds = window.eds ? window.eds : (single ? window.location.href.split('/')[4] : window.location.href.split('/')[3]);
  await fetch(config.apiGateway.URL+'/terminal/' + eds + '/' + terminalId)
  .then(response => {
    if (response.ok) {
      return response.json();
    }
  })
  .then(data => {
    appVars.data = data;
    appVars.demos = data.app[appVars.defaultLang];
    appVars.backgrounds.landscape = data.file_landscape;
    appVars.backgrounds.portrait = data.file_portrait;
    response.demos = appVars.demos;
    return response;
  })
  .catch(error => {
    //error
    response.error = true;
    response.message = error;
    return response;
  });
}

export default wtsFunctions;