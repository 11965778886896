// http://localhost:3001
// https://admincapca.welcometouchsmart.com
// https://devcapca.welcometouchsmart.com
// https://cpp10-smt.ca-technologies.fr
// https://demo.welcometouchsmart.com/ // Plateforme demo netisse

const dev = {
  apiGateway: {
    // BO
    URL: "https://smt-capca.welcometouchsmart.com/api",
    // BORNE WTS
    WTS: "https://democapca.welcometouchscreen.com/v4",
  },
};

const prod = {
  apiGateway: {
    URL: "/api",
    WTS: "https://democapca.welcometouchscreen.com/v4", // WTS CABP
    // WTS: "https://democapca.welcometouchscreen.com/v4", 
    // WTS: "https://cpp10-net.ca-technologies.fr/v4", // WTS CAPCA
  },
};

const config = process.env.NODE_ENV === 'production'
  ? prod
  : dev;

export default {
  APP_NAME: "WELCOMETOUCH SMART",
  ...config
};
