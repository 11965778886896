import React from 'react';
import { useParams } from "react-router-dom";
import wtsFunctions from '../services/ServiceManager.js';
import Footer from '../layout/Footer.js';
import Demo from '../components/demo/Demo.js';


function DemoPage() {
  const { demoId } = useParams();
  const currentDemo = window.currentDemo ? window.currentDemo : wtsFunctions.getDemoById(demoId);  
  window.currentDemo = currentDemo;
  return (
    <div className="App">
      <Demo demoId={demoId}></Demo>
      <Footer>
      </Footer>
    </div>
  );
}

export default DemoPage;
