import React from 'react';
import Application from './components/application/Application.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css';

//Landscape Style - Responsive
import './assets/css/landscape.css';

function App() {
  return (
    <Application></Application>    
  );
}

export default App;
