import React, { Component } from 'react';
import tickIcon from '../../assets/images/icons/tick.svg';

class Popup extends Component {
  render() {
    return (
      <div className="popup-success">
        {this.props.success && <div className="popup-icon"><img src={tickIcon} alt="Success"/></div>}
        <div className="popup-message">{this.props.message}</div>        
      </div>
    );
  }
}

export default Popup;
