import React from 'react';
// import axios from 'axios';
import {
  withRouter
} from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import config from '../config.js';
import Share from '../components/share/Share.js';
import Popup from '../components/popup/Popup.js';

import homeIcon from '../assets/images/icons/home.svg';
import mobileMsgIcon from '../assets/images/icons/mobile-msg.svg';
import closeIcon from '../assets/images/icons/close-share.svg';

window.exitPopup = false;

const Toggle = () => {
  const [show, toggleShow] = React.useState(true);
  if(show){
    document.getElementById('root').classList.remove('popup-opened')
  } else {
      if(window.exitPopup) closeExistPopup();
      document.getElementById('root').classList.add('popup-opened')
  }
  return (
      <Col sm={4} className="text-right d-inline">
        {!show && <Share hideShare={() => toggleShow(!show)}></Share>}
        <div onClick={() => toggleShow(!show)}>
          <span>{show ? 'Recevoir' : 'Fermer'}</span>
          <div className="footer-icon">
            <img src={show ? mobileMsgIcon : closeIcon} alt="Share"/>
          </div>
        </div>
      </Col>    
  )
}

function goHome() {
  // console.log('Go Home');
  var currentLocation = window.location.pathname;
  if(currentLocation.includes('demo') === false && currentLocation.includes('affluence') === false) {
    return;
  }
  if(!window.back_home) {
    stopVisit();
    return;
  }
  window.exitPopup = true;
  document.getElementsByClassName("demo-frame-container")[0].classList.add('send-background');
}

function closeExistPopup(){
  document.getElementsByClassName("demo-frame-container")[0].classList.remove('send-background');
  window.exitPopup = false;
}

function confirmExit() {  
  closeExistPopup();
  stopVisit();
}

function stopVisit() {
  // END VISIT : /visit/end/{appUses}
  /*
  const visit = { demoId:window.currentDemo, visitId: window.visitId };
  const headers = { 
      'Authorization': 'Bearer wts-token',
  };
  axios.post(config.apiGateway.URL+'/visit/end/'+window.visitId, visit, { headers })
  .then(response => { 
    window.location.href = '/terminal/' + window.terminalId;
  });
  */
 console.log('/terminal/'+window.eds+'/'+window.terminalId);
  if(window.visitId) {
    fetch(config.apiGateway.URL+'/visit/end/' + window.visitId)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    })
    .then(data => {
      // End visit
      document.location.href = '/terminal/'+window.eds+'/'+window.terminalId;
    })
    .catch(error => {
      //error
    });
  }
  else {
    document.location.href = '/terminal/'+window.eds+'/'+window.terminalId;
  }
}

function Footer() {
  return (
    <footer className="App-footer navbar fixed-bottom">
      <Container fluid>
        <Row className="w-100 m-0">
          <Col sm={4}></Col>
          <Col sm={4} className="text-center d-inline" onClick={() => goHome()}><div className="footer-icon"><img src={homeIcon} alt="Home"/></div>{false && <span className="pl-3">Accueil</span>}</Col>
          { window.currentDemo && window.currentDemo.share && 
            (
              (window.currentDemo.share.hasOwnProperty('tel') && window.currentDemo.share.tel === "1") || 
              (window.currentDemo.share.hasOwnProperty('mail') && window.currentDemo.share.mail === "1")
            ) && Toggle() 
          }
        </Row>
        <Row>
          {window.exitPopup && 
            <Popup message={<Col id="exit-popup">
              <p>Êtes-vous sûr de vouloir quitter ?</p>
              <Button onClick={()=>closeExistPopup()}>Non</Button>
              <Button onClick={()=>confirmExit()}>Oui</Button>
              </Col>
            }>

            </Popup>
          }
        </Row>
      </Container>        
    </footer>
  );
}

export default withRouter(Footer);
