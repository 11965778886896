import React, { Component } from 'react';
import { Container} from 'react-bootstrap';
import { Link } from "react-router-dom";
// import axios from 'axios';
import Carousel from 'react-grid-carousel'
import appVars from '../../services/appGlobals.ts';
import config from '../../config.js';
import wtsFunctions from '../../services/ServiceManager';

import affluenceIcon from '../../assets/images/affluence.png';


class DemosList extends Component {

  constructor(props) {
    super(props);
    
    const cols = window.innerWidth > window.innerHeight ? 5 : 3;
    const rows = window.innerWidth > window.innerHeight ? 2 : 3;
    this.state = {
      demos: [],
      affluenceDemo: wtsFunctions.getAffluenceDemo(),
      isLoading: true,
      error: null,
      wid8th: 0,
      height: 0,
      swiped: false,
      carousel: {cols: cols, rows:rows, gap:5}
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.startVisit = this.startVisit.bind(this)  
    
    this._onTouchStart = this._onTouchStart.bind(this);
    this._onTouchMove = this._onTouchMove.bind(this);
    this._onTouchEnd = this._onTouchEnd.bind(this);

    this._swipe = {};
    this.carousel = React.createRef();
    this.minDistance = 50;
  }

  componentDidMount() {
    const terminalId = window.terminalId ? window.terminalId : window.location.href.split('/').slice(-1).pop();
    const eds = window.eds ? window.eds : window.location.href.split('/')[4];
    this.setState({ isLoading: true });
    fetch(config.apiGateway.URL+'/terminal/' + eds + '/' + terminalId)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Une erreur est survenue ...');
      }
    })
    .then(data => {
        appVars.data = data;
        appVars.demos = data.app[appVars.defaultLang];        
        appVars.backgrounds.landscape = data.file_landscape;
        appVars.backgrounds.portrait = data.file_portrait;
        window.borne_id = data.id;
        window.agency_name = data.agency_name;
        window.inactivityTimer = +data.inactivityTimer;
        window.eds = data.agency_eds;
        window.back_home = data.back_home;
        window.screensaver_display = data.screensaver_display;
        if(data.screensaver_display && (data.file_screensaver_landscape || data.file_screensaver_portrait)){
          window.screensaver_display_duration = +data.screensaver_display_duration;
          appVars.video.portrait = data.file_screensaver_portrait;
          appVars.video.landscape = data.file_screensaver_landscape;
          window.file_screensaver_type = data.file_screensaver_type;
        }
        else {
          window.screensaverOn = false;
        }
        document.getElementById('home-container').style.backgroundImage = window.innerWidth > window.innerHeight ? ('url("'+(appVars.backgrounds.landscape ? appVars.backgrounds.landscape : appVars.backgrounds.default_landscape)+'")') : ('url("'+(appVars.backgrounds.portrait?appVars.backgrounds.portrait:appVars.backgrounds.default_portrait)+'")');
        document.getElementById('agency-name').innerHTML = data.agency_name;
        if(data.agency_name_color) {
          document.getElementById('agency-name-container').style.color = data.agency_name_color;
        }
        this.setState({ eds: data.agency_eds, terminalId: window.terminalId, demos:appVars.demos, isLoading: false });
        this.props.updateGlobals();
      })
      .catch(error => this.setState({ error, isLoading: false }));

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    let cols = 3;
    let rows = 3;
    let gap = 3;
    if(window.innerWidth > window.innerHeight){
      cols = 5;
      rows = 2;
      gap = 5;
    }
    this.setState({ width: window.innerWidth, height: window.innerHeight, carousel: {cols: cols, rows:rows, gap:gap} })
    // Update background image
    document.getElementById('home-container').style.backgroundImage = window.innerWidth > window.innerHeight ? ('url("'+(appVars.backgrounds.landscape ? appVars.backgrounds.landscape : appVars.backgrounds.default_landscape)+'")') : ('url("'+(appVars.backgrounds.portrait?appVars.backgrounds.portrait:appVars.backgrounds.default_portrait)+'")');
    this.props.updateGlobals();
  }

  startVisit(demo) {
    window.currentDemo = demo;
    // fetch(config.apiGateway.URL+'/visit/start/'+window.terminalId+'/'+demo.app_id+'/'+appVars.defaultLang)
    // SEND Borne_ID
    fetch(config.apiGateway.URL+'/visit/start/'+window.borne_id+'/'+demo.app_id+'/'+appVars.defaultLang)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    })
    .then(data => {
      window.visitId = data.id;
    })
    .catch(error => {
      //error
    });
  }

  _onTouchStart(e) {
    const touch = e.touches[0];
    this._swipe = { x: touch.clientX };
  }

  _onTouchMove(e) {
    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.changedTouches[0];
      this._swipe.swiping = true;
      // console.log("swiping");
    }
  }

  _onTouchEnd(e) {
    const touch = e.changedTouches[0];
    const absX = Math.abs(touch.clientX - this._swipe.x);
    if (this._swipe.swiping && absX > this.minDistance ) {
      this.props.onSwiped && this.props.onSwiped();
      if(touch.clientX < this._swipe.x){
        // console.log("swiped left");
        document.querySelector("span[type='next']").click();
      }
      else{
        // console.log("swiped right");
        if(!document.querySelector("span[type='prev']").parentElement.hasAttribute('hidden'))
        document.querySelector("span[type='prev']").click();
      }
    }
  }

  render() { 
    const { demos, isLoading, error } = this.state;
    const responsive = [      
      {
        breakpoint: 1920,
        cols: 5,
        rows: 2,
        gap: 5,
      },
      {
        breakpoint: 1080,
        cols: 3,
        rows: 3,
        gap: 5,
      },
    ];

    if (error) {
      return <p>{error.message}</p>;
    }
 
    if (isLoading) {
      return <p>Loading ...</p>;
    }
    return (
      <Container fluid className="demo-list-container" onTouchStart={this._onTouchStart} onTouchMove={this._onTouchMove} onTouchEnd={this._onTouchEnd}>
        <Carousel id="carousel" cols={this.state.carousel.cols} rows={this.state.carousel.rows} gap={this.state.carousel.gap} hideArrow={false} showDots={true}  >{/*responsiveLayout={responsive} */}
          { config.apiGateway.WTS !== "" && 
            <Carousel.Item key={`demo-affluence`} >
              <div className="demo-item-container justify-content-center align-items-center">
                <Link to={`/terminal/${this.state.eds}/${this.state.terminalId}/affluence`}>
                  <div className="demo-item d-flex justify-content-center align-items-center">
                    <img src={affluenceIcon} alt={this.state.affluenceDemo.app_name} />
                  </div>
                  <span className="demo-item-name" style={{color:this.state.affluenceDemo.app_name_color}}>{this.state.affluenceDemo.app_name}</span>
                </Link>
              </div>
            </Carousel.Item>
          }
          {
                  demos.map((demo) =>
                  <Carousel.Item key={`demo-${demo.app_id}`} >
                    <div className="demo-item-container justify-content-center align-items-center" onClick={() => this.startVisit(demo)}>
                      <Link to={`/terminal/${this.state.eds}/${this.state.terminalId}/demo/${demo.app_id}`}>
                        <div className="demo-item d-flex justify-content-center align-items-center">
                          <img src={demo.app_icon} alt={demo.app_name} />
                        </div>
                        <span className="demo-item-name" style={{color:demo.app_name_color}}>{demo.app_name}</span>
                      </Link>
                    </div>
                  </Carousel.Item>
                  )
          }
          {
            
          }
        </Carousel>
      </Container>
    );
  }
}

export default DemosList;
