import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import config from '../../config.js';
import DemoPage from '../../pages/DemoPage.js';
import HomePage from '../../pages/HomePage.js';
import AffluencePage from '../../pages/AffluencePage.js';
import { history } from '../../services/history.js';


class Application extends Component {


  constructor(props) {
    super(props)
    this.state = { redirect: null, timeout: +window.inactivityTimer, screensaverOn: false };
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)

    setTimeout(() => {
      if(this.state.timeout != +window.inactivityTimer){
        this.setState({timeout: +window.inactivityTimer});
        window.idleRef.reset();
      }
    }, 1000);
    window.screensaverOn = false;

  }

  render() {
    return (        
      <Router history={history}>
      { this.state.redirect &&  
        <Redirect to={this.state.redirect} />
      }
        <div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref; window.idleRef = ref; }}
            timeout={(this.state.timeout) ? (1000 * this.state.timeout) : (1000 * 60 * 3)}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          />
          <Switch>
            <Route name="demo" path="/terminal/:eds/:terminalId/demo/:demoId">
              <DemoPage></DemoPage>
            </Route>
            <Route name="terminal" path="/terminal/:eds/:terminalId/affluence">
              <AffluencePage></AffluencePage>
            </Route>
            <Route name="terminal" path="/terminal/:eds/:terminalId">
              <HomePage screensaverOn={this.state.screensaverOn}></HomePage>
            </Route>
            <Route path="/">
              {/* <Redirect to="/terminal/0" /> */}
             
              <div className="bg-light d-flex flex-row justify-content-center align-items-center vh-100">
                <div className="p-2">
                  <h1>Bienvenue sur SMART CAPCA</h1>
                </div>
              </div>
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }

  handleOnAction (event) {
    console.log('user did something', event)
    this.setState({ redirect: null, screensaverOn: false});
    window.screensaverOn = false;
  }

  handleOnActive (event) {
    console.log('user is active', event)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  } 

  handleOnIdle (event) {
    console.log('user is idle', event)
    window.exitPopup = false
    this.stopVisit(true);
    const path = "/terminal/" + window.eds + "/" + window.terminalId;
    if(window.location.pathname !== path){ //!window.screensaverOn &&
      history.push(path);
      history.push(path);
      history.go(-1);
    }
    setTimeout(() => {
      if(window.screensaver_display) { //  && this.state.screensaverOn === false
        window.screensaverOn = true;
        this.setState({screensaverOn:true}, () => {        
          console.log("set screensaver on", window.screensaverOn, this.state.screensaverOn);
        });
      }
    }, 1000);
    // console.log('last active', this.idleTimer.getLastActiveTime())
  }

  stopVisit(noRedirect = false) {
    if(window.visitId) {
      fetch(config.apiGateway.URL+'/visit/end/' + window.visitId)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        // End visit
        if(!noRedirect) window.location.href = '/terminal/' + window.eds + '/' + window.terminalId;
      })
      .catch(error => {
        //error
      });
    }
    else {
      if(!noRedirect) window.location.href = '/terminal/' + window.eds + '/' + window.terminalId;
    }
  }
}

export default Application;
